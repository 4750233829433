import { React, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import { Typography, Fade } from '@mui/material';






const Loading = (props) => {
    const [mensajeRancio, setMensajeRancio] = useState('Cargando...')
    const [bgImage, setBgImage] = useState('7')
    const bg = ['1','6','3', '4', '7','9']
    const msg = ['"Mejor llorar que reír..."','"-Yo no se si porque hay 6 cosos, si tengo 5 y aca veo 4"...','"-Aprende de esos errores y tratá de repetirlos..."','"Jugando mi promo a diamante para subir a oro..."','La respuesta se pregunta sola...']


    
    useEffect(() => {
        const random = Math.floor(Math.random() * bg.length)
        const randomMsj = Math.floor(Math.random() * msg.length)
        setBgImage(bg[random])
        if(!props.message){
            setMensajeRancio(msg[randomMsj])
        }else{
            setMensajeRancio(props.message)
            setTimeout(()=>{
                setMensajeRancio('Ya casi estamos...')
            },3000)
            setTimeout(()=>{
                setMensajeRancio('Esto esta tardando un poco más de lo usual..')
            },10000)

        }
        


        
        setInterval(() => {
            if(!props.message){
                const randomMsj = Math.floor(Math.random() * msg.length)
                 setMensajeRancio(msg[randomMsj])
            }
            const random = Math.floor(Math.random() * bg.length) 
            setBgImage(bg[random])  
        }, 10000)
    }, [])



    return (
   
        <Backdrop
            transitionDuration={250}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,background: `linear-gradient(to right, rgb(59 59 183 / 0%), rgb(74 90 163)),url(/img/${bgImage}.jpg),black`, backgroundPosition: "76% 4%", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', transition:'.7s'}}
            open={true}
        >


            <Stack spacing={1} sx={{ width: '100%', alignItems: 'center' }}>

                <img src="/img/tarmydotcomlogowhite.png" alt="talonarmylogo" style={{ zIndex: 2 }} />
                <CircularProgress color="inherit" />
              
                    {mensajeRancio}
               
                
            </Stack>


        </Backdrop>
        
    )

}

export default Loading;