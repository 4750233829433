import { React } from 'react';
import { Grid, Box,Typography, Paper, CardMedia, Button, } from '@mui/material';

const IdxYasBot = () => {
  return (<>
    <Grid container component={Paper} sx={{ borderRadius: 0, overflow: 'hidden' }}>
       <Grid item xs={12} md={12} sx={{alignItems:'center',background:'linear-gradient(to right, rgb(20 3 161 / 80%), rgb(13 31 114 / 0%)),url(/img/y1.jpg)', minHeight:'100vh', backgroundPosition:'0% 22%', backgroundSize:'cover'}}>
        
       <Typography align={'left'} variant={'h5'} sx={{  marginTop:'15vh',fontStyle: 'italic'}}>     
       <img src={'/img/ybot.png'} alt={'Yasuo Bot'} height={100}/>    
      </Typography> 
      <Typography align={'left'} variant={'body1'} sx={{ marginLeft:'5vh', marginTop:'5vh',fontStyle: 'italic'}}>
          Nuestro botcito, y el pilar de este sitio web.
      </Typography>
      <Typography align={'left'} variant={'body1'} sx={{ marginLeft:'5vh', marginTop:'5vh',fontStyle: 'italic'}}>
      <Button variant={'outlined'} color={'info'} sx={{p:2}} >
         Ir al micrositio</Button>
      </Typography>

      
       </Grid>      
    </Grid>
   
</>
  )

}

export default IdxYasBot;