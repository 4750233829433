import * as React from 'react';
import { Button,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,Slide} from '@mui/material';
import { useSpring, animated } from 'react-spring'
import { useState, useEffect } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function ConfirmacionCard({ open, canvasRef, handleClose, handleConfirm }) {

    const [flipped, setFlipped] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setFlipped(!flipped)
        }, 1800)

        
    }, [flipped])

    const { transform, opacity } = useSpring({
        transform: `perspective(800px) rotateY(${flipped ? 15 : 0}deg)`,
        config: { mass: 25, tension: 25, friction: 20 },
    })


    const preview = () => {
        const canvas = canvasRef.current
        const dataURL = canvas.toDataURL('image/png')
        localStorage.setItem('memcardBorrador', dataURL)
        return localStorage.getItem('memcardBorrador')
    }

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirmar generación"}</DialogTitle>
                <DialogContent dividers>

                    <DialogContentText sx={{ padding: 2 }}>
                        ¿Seguro que queres confirmar? La memcard va a verse así, y no vas a poder volver a editarla por unos días.


                    </DialogContentText>
                    <Grid container justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
                        <animated.div style={{opacity, transform}}>
                            <img src={preview()} alt="preview" height={"80%"} width={"80%"} />
                        </animated.div>

                    </Grid>



                </DialogContent>
                <DialogActions>
                    <Button color={'martha'} variant='outlined' onClick={handleClose}>Volver</Button>
                    <Button color={'martha'} variant='outlined' onClick={handleConfirm}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
