
import LoadingCard from "../LoadingCard";

import { Grid, Paper, Typography, Card, Avatar } from "@mui/material";
import ListChampsMastery from "../ListChampsMastery";
import { useContext } from "react";
import UserContext from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";



export const RiotProfile = (props) => {
  const { isAuthenticated } = useAuth0();
  const { usr: userMetadata } = useContext(UserContext)
  const { bgData } = props;
  const { riotData: { profileIconId, name, region },version } = userMetadata;
  const iconRiotURL = `https://${process.env.REACT_APP_RIOT_CDN_URL}/${version}/img/profileicon/${profileIconId}.png`
  const { listaSkinsId, elegirRandomSkin } = bgData;
  const bgPortada = `https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/${listaSkinsId[elegirRandomSkin(listaSkinsId.length)].id}.jpg`


  return (isAuthenticated && (
    <Grid container spacing={0} component={Paper} sx={{ minHeight: '100vh', borderRadius: '0' }}>
      <Grid item xs={12} md={12} component={Paper} sx={{ alignContent: 'center' }}>


        <Card sx={{ background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),url(${bgPortada})`, backgroundPosition: "10% 25%", backgroundSize: '120vw', minHeight: '20em' }}>
          <Avatar
            alt="ProfileImage"
            src={iconRiotURL}
            align='center'
            sx={{ border: '8px solid #1F1B24', marginY: '13em', position: 'absolute', marginX: '5em ', width: 150, height: 150 }}
          />
          <Avatar
            alt="RiotLogo"
            src="/img/riot.png"
            sx={{ p: 0, position: 'absolute', border: '8px solid #1F1B24', marginY: '18.2em', marginX: '10em', width: '40px', height: '40px' }}
          />
        </Card>


        <Grid item xs md >

          <Typography variant={'h4'} sx={{ minHeight: '2em', marginTop: '.3em', marginLeft: '8em' }} >
            {`${name} - ${region}`}
          </Typography>



        </Grid>





        <Grid container spacing={0} component={Paper} elevation={5} sx={{minHeight:'50vh'}}>

          <Grid item md={12} xs={12} sx={{ m: 3, minHeight: '2rem' }}>
            <Typography align={'center'} variant={'h3'} >
              Mis mains
            </Typography>


          </Grid>

          <Grid container spacing={0} sx={{display:'flex',gap:'2em', justifyContent:'center'}}>

            <ListChampsMastery userMetadata={userMetadata} />


          </Grid>



          {/* <Grid item md={12} xs={12} sx={{ m: 3 }}>
            <Typography align={'center'} variant={'h3'} >
              Mi liga

            </Typography>
            <LoadingCard />
            </Grid> */}




        </Grid>


      </Grid>



    </Grid>


  )
  )

}
