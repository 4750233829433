import React, { useEffect, useState } from 'react'
import LoadingCard from "../profile/LoadingCard";
import { Tilt } from '../Tilt'
const dataURL = `https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/`


const MemcardPreview = props => {

  

    const loadImage = async (url) => {
        
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
        });
    }

    const cWidth = 808;
    const cHeight = 500;

    const { body, canvasRef } = props

    const dateOfCreation = new Date().toLocaleDateString('es', { year: 'numeric', month: 'numeric', day: 'numeric' })
    const Roboto = new FontFace("Roboto-Thin", "url(../theme/Roboto-Thin.ttf)");
    const draw = async (ctx) => {

        //Limpio el canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        let fondo;
        if (!body.wallpaperURL) {
            fondo = await loadImage(`${dataURL}${body.skinId}.jpg`).catch(err => console.log(err))
        } else {
            fondo = await loadImage(body.wallpaperURL)
        }

        //CORTAR IMAGEN EN FORMA DE TARJETA
        function roundRect(ctx, x, y, width, height, radius) {
            if (typeof radius === "undefined") {
                radius = 5;
            }
            if (typeof radius === "number") {
                radius = {
                    tl: radius,
                    tr: radius,
                    br: radius,
                    bl: radius
                };
            } else {
                const defaultRadius = {
                    tl: 0,
                    tr: 0,
                    br: 0,
                    bl: 0
                };
                for (let side in defaultRadius) {
                    radius[side] = radius[side] || defaultRadius[side];
                }
            }
            ctx.beginPath();
            ctx.moveTo(x + radius.tl, y);
            ctx.lineTo(x + width - radius.tr, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
            ctx.lineTo(x + width, y + height - radius.br);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
            ctx.lineTo(x + radius.bl, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
            ctx.lineTo(x, y + radius.tl);
            ctx.quadraticCurveTo(x, y, x + radius.tl, y);
            ctx.closePath();
        }
        //coso que corta
        roundRect(ctx, 0, 0, cWidth, cHeight, 38);
        ctx.clip();
        ctx.clearRect(0, 0, cHeight, cWidth);
        //Poner imagen atras del coso k corta
        switch (body.bgRadio) {
            case 1://aspect radio normal
                ctx.drawImage(fondo, 0, 0, 1280, 720);
                break;
            case 2://aspect radio igual al fondo
                ctx.drawImage(fondo, 0, 0, 808, 500)
                break;
            case 3://Aspect radio chiquito1
                ctx.drawImage(fondo, 0, 0, 1024, 576)
                break;
            case 4://Aspect radio grande
                ctx.drawImage(fondo, -500, -200, 1920, 1080)
                break;
            default:
                ctx.drawImage(fondo, 0, 0, 1280, 720);
                break;
        }
        const letrasFondo = await loadImage(`./resources/pre/${body.preId}.png`)

        ctx.drawImage(letrasFondo, 0, 0, cWidth, cHeight)
        Roboto.load().then(() => {
            document.fonts.add(Roboto)
            ctx.font = "16px Roboto-Thin"
            ctx.fillStyle = '#ffffff';
            //server
            ctx.fillText(body.serverLoL, 734, 115)

            //Fecha de creacion
            ctx.fillText(`Valid from ${dateOfCreation}`, 75, 480)

            ctx.font = "30px Roboto-Thin"
            ctx.fillStyle = '#ffffff';
            //masteryPoints
            ctx.fillText(body.masteryPoints, 145, 244)

            //LolName
            ctx.fillText(body.lolName, 145, 304)

            //cardCode
            ctx.fillText(body.cardNumber, 145, 360)

            //discordId
            ctx.fillText(body.discordTag, 145, 430)


        })

        //avatar
        const avatar = await loadImage(body.avatarURL)

        ctx.drawImage(avatar, 610, 295, 150, 150)


    }

    useEffect(() => {
        
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        draw(context)


    }, [body, draw])





    return (
      <Tilt><canvas ref={canvasRef} width={cWidth} height={cHeight} /></Tilt>
    )
}

export default MemcardPreview