import { Typography, Paper, Grid, Button, Avatar, LinearProgress } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useState } from "react";
import { ValidationStatus } from "./addNewAccount/ValidationStatus";


const iconRiotURL = `https://ddragon.leagueoflegends.com/cdn/12.19.1/img/profileicon/5331.png`

export const RiotVinculate = () => {


 
  const [isFetching, setIsFetching] = useState(false)


  // To Do: crear un condicional para mostrar o no el formulario dependiendo del estado de la validacion
  // Crear un componente para mostrar el estado de la validacion
  return (

    <> {isFetching ? <LinearProgress variant="indeterminate" color="success"/> : null}
    <Grid container spacing={0} component={Paper} sx={{ minHeight: '100vh', borderRadius: '0' }}>

       <>
      
       <Grid item xs={false} sm={false} md={7}>

        <img src="/img/wallp2.jpg" alt="fondo" style={{ display: 'flex', width: '100%', height: '100%', objectFit: 'cover' }} />

      </Grid>
        <Grid item xs={0} md={5}>
       
          <Grid container spacing={0} sx={{ display: 'flex', flexDirection: 'row', marginTop: '4em', justifyContent: 'center', rowGap: '1em', alignItems: 'baseline' }}>
            <Grid item md={1} ></Grid>
            <Grid item md={3} >
              <Avatar
                alt="ProfileImage"
                src={iconRiotURL}
                sx={{ border: '4px solid #fff', width: 150, height: 150, p: 0 }}
              />
              <Avatar
                alt="RiotLogo"
                src="/img/riot.png"
                sx={{ p: 0, position: 'absolute', border: '8px solid #1F1B24', marginY: '-2.8em', marginX: '5em', width: '50px', height: '50px' }}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={2}> <SyncAltIcon sx={{ fontSize: '8em', textAlign: 'center', justifyContent: 'center' }} />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={3} sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Avatar
                alt="YasBot"
                src={'/img/yasuobot.jpg'}

                sx={{ border: '4px solid #fff', margin: '0 auto', width: '150px', height: '150px' }}
              />
            </Grid>
            <Grid item md={1} ></Grid>




            <Grid item md={12} sx={{ marginTop: '5vw' }}>

              <Typography align={'center'} variant={'h4'} sx={{ fontFamily: 'Roboto-Thin' }}>
                Vincular tu cuenta Riot con YasuoBot
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} sx={{ marginBottom: '2em' }}>
            <ValidationStatus setIsFetching={setIsFetching}/>
            </Grid>



          </Grid>













        </Grid>
       </>
      

    </Grid>
    </>
  )
}
