import { Grid, Fade, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Tilt } from "../Tilt";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ShareIcon from '@mui/icons-material/Share';
import ConfettiExplosion from 'react-confetti-explosion';


export const MemberCardSuccess = (props) => {

  const [explosion, setExplosion] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setExplosion(true);

    }, 1200);
    setTimeout(() => {
      setExplosion(false);

    }, 2800);

  }, [setExplosion]);




  const memcard = localStorage.getItem('memcardPreview')


  const [checked, setChecked] = useState(true);

  const options = {
    duration: 3600,
    particleCount: 80,
    height: 3600,
    width: 3500
  }

  return (
<Grid container sx={{ minHeight: '100vh', background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),#121212`, backgroundSize: '100%' }}>

  <Grid item xs={2} md={0}></Grid>
  <Grid item xs={8} md={12}>
    <Grid container direction="column"
      justifyContent="center"
      alignItems="center" spacing={2} >

      <Grid item md>

        <Typography variant={'h3'} align={'center'} sx={{ marginTop: '1em', color: '#fff' }}>
          ¡Pero mirá que linda que quedó!
        </Typography>
      </Grid>

      <Grid item md={12}>
        {explosion && <ConfettiExplosion {...options} />}
      </Grid>

      <Grid item md sx={{ marginTop: '1em' }}>
        {/*Memcard*/}


        
        <Tilt style={{ width: "100%" }}>
        <Fade in={checked} timeout={2800}>
          <img src={memcard} style={{ width: '100%' }} />
            </Fade>
        </Tilt>
      









      </Grid>
      <Grid item md sx={{ marginTop: '2em' }}>
        <Button variant={'outlined'} color={'martha'} sx={{ fontFamily: 'Roboto-Thin', fontSize: '2em', textTransform: 'none' }}>
          <CloudDownloadIcon />
          <span style={{ marginLeft: '.3em' }}>Descargar
          </span> </Button>
        <Button variant={'outlined'} color={'martha'} sx={{ fontFamily: 'Roboto-Thin', fontSize: '2em', textTransform: 'none' }}>
          <ShareIcon />
          <span style={{ marginLeft: '.3em' }}>Compartir
          </span> </Button>

      </Grid>



    </Grid>
  </Grid>
  <Grid item xs={2} md={0}></Grid>
</Grid>

    
  
  )
}
