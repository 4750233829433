import { useState, useEffect} from 'react';

import { getSkinsList } from '../services/championService';

export const useRiotWallpaper = ({ userMetadata }) => {



  let riotData;
  if (userMetadata) {
    riotData = userMetadata.isRiotLinked ? userMetadata.riotData : userMetadata.isRiotLinked;
  }

    const [bgData, setBgData] = useState({});
    useEffect(() => {
        const setSkins = async () => {
          let champId;
          if (riotData) {
            champId = userMetadata.masteryData[0].championId
    
          } else {
            champId = 91
          }
    
          await getSkinsList(champId.toString()).then(res => {
            const skinsLen = res.skins.length;
            const elegirRandomSkin = (skinsLength) => Math.floor(Math.random() * skinsLength)
            const dataMain = { bgPortada: `https://akame.talonarmy.com/s/centered/${res.skins[elegirRandomSkin(skinsLen)].id}.jpg`,
            listaSkinsId: res.skins,
            elegirRandomSkin,
            riotData
            }
            setBgData(dataMain)
          })
    
        }
        setSkins()
      }, [userMetadata,setBgData,riotData])




  return [bgData,setBgData]
}
