import { React } from 'react';
import { Divider, Card, Grid, Typography, Paper,Box} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
const Main = (props) => {
  return (<>


    <Grid container component={Paper} sx={{minHeight:'100vh', borderRadius: 0, overflow: 'hidden' }}>
    

      <Grid item xs={12} md={12}>
      <Card sx={{ background: 'url(/img/9.jpg)',minHeight:'32vh', backgroundSize: '250vh', backgroundPosition: '65% 5%', borderRadius: '0', }}>
          
        </Card>
        <Box sx={{ p: 3}} component={Paper} elevation={5} >
        <Typography align={'center'} variant={'h3'} sx={{ marginBottom:'2vh',fontStyle: 'italic' }}>
      <img src='/img/yashello.png' alt='yashello' style={{opacity:'100%'}}/>       
      </Typography>
      <Typography align={'center'} variant={'h4'} sx={{  marginBottom:'2vh',fontStyle: 'italic' }}>
      Te damos la bienvenida, {props.usr.nickname}.
      </Typography>
      <Typography align={'center'} variant={'h6'} sx={{  marginBottom:'2vh',fontStyle: 'italic' }}>
      Desplazate por el menu superior para acceder a las funciones implementadas.
      </Typography>
        </Box>
      
        <Card sx={{ background: 'url(/img/7.jpg)',minHeight:'42vh', backgroundSize: '250vh', backgroundPosition: '25% 7%', borderRadius: '0', }}>
          
          </Card>
       </Grid>
       <Grid item xs={12} md={12}>
        
       <Box component={Paper} elevation={3} >
       
      <Typography align={'center'} variant={'button'}>
        Alpha |  Developed with <FavoriteIcon fontSize='20px'/> by gpamic</Typography>
        </Box>

      </Grid>

    </Grid>
    </>
  );
}

export default Main;