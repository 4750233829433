import { Box, Typography, Grid, Autocomplete, FormControlLabel, Switch, TextField, CardMedia, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getChampionList, getSkinsList } from '../../services/championService'
import { useMemcardGen } from "../../hooks/useMemcardGen";



export const MemberCardForm = ({ setLoadBarValue, procesarMemcard, setMemcardRequest, memcardRequest, card }) => {


    const [championsList, setChampionsList] = useState();
    const [champSkins, setChampSkins] = useState();
    const [inputSkin, setInputSkin] = useState();
    const [isCustomBg, setIsCustomBg] = useState(false);
    const [bgCustomURL, setBgCustomURL] = useState("")
    const bgRadioOptions = [{ label: "Enfoque", id: 1 }, { label: "Splash FullSize", id: 2 }, { label: "Standard", id: 3 }, { label: "Ampliado", id: 4 }]

    const [dataCustomBG, setDataCustomBG] = useState({});


    const verificarImagen = (url) => {
        if (!url.match(/\.(jpg|jpeg|png|gif)$/)) {
            setDataCustomBG({ error: true, helperText: "URL no válida." })
        } else {

            setDataCustomBG({})
            setMemcardRequest({ ...memcardRequest, wallpaperURL: url })
            setBgCustomURL(url)
        }

    }



    const getChampSkins = async (id) => {
        await setLoadBarValue(1)
        const { skins } = await getSkinsList(id)
        setTimeout(() => {
            setLoadBarValue(99)
            setTimeout(() => {
                setLoadBarValue(100)
            }, 700)
        }, 500)
        const skinLabel = skins.map(skin => {
            if (skin.name === 'default') {
                return { label: 'Classic', id: skin.id }
            } return { label: skin.name, id: skin.id }
        })
        setChampSkins(skinLabel)

    }

    const handleCustomBg = () => {
        setIsCustomBg(!isCustomBg)
        inputSkin ? setInputSkin(false) : setInputSkin({})
    }




    useEffect(() => {

        const getList = async () => {
            const data = await getChampionList()
            setChampionsList(data)

        }
        getList()
        
    }, [])





    return (
        <Grid item xs={4} md={4} sx={{ alignContent: 'left' }}>
            <Box sx={{ m: 1, p: 1 }}>
                <Typography align={'left'} variant={'h5'} sx={{ m: 2 }}>
                    Fondo
                </Typography>
                {!isCustomBg && championsList && <Autocomplete
                    id="champ"
                    options={championsList}
                    sx={{ width: 300, m: 3 }}
                    renderInput={(params) => <TextField {...params} label="Seleccione Champ" />}
                    onChange={(event, newValue) => {
                        getChampSkins(newValue.id)
                    }}
                />}
                {!champSkins && <FormControlLabel sx={{ m: 3, display: 'flex' }} control={<Switch checked={isCustomBg} onChange={handleCustomBg} />} label="Fondo personalizado" />}
                {isCustomBg && <><Typography variant={'subtitle2'} sx={{ m: 3 }} >Sugerimos usar una imagen de 1920x1080px</Typography>
                    <TextField id="outlined-basic" value={bgCustomURL} onChange={(event) => {

                        const wallpaperURL = event.target.value
                        verificarImagen(wallpaperURL)

                    }} inputProps={{ inputMode: 'url' }} sx={{ m: 3, display: 'flex', width: 300 }} label="URL fondo" variant="outlined" {...dataCustomBG} />




                </>}

                {champSkins && <Autocomplete
                    id="skins"
                    options={champSkins}
                    sx={{ width: 300, m: 3 }}

                    renderInput={(params) => <TextField {...params} label="Seleccione Skin" />}
                    onChange={(event, newValue) => {
                        //COMO CAMBIAR DATOS DENTRO DE UN OBJETO.
                        setInputSkin(newValue.id)
                        let upd = {}
                        upd['skinId'] = newValue.id
                        setMemcardRequest({ ...memcardRequest, ...upd })
                    }}
                />}

                {inputSkin &&

                    <Autocomplete
                        id="radio"
                        options={bgRadioOptions}
                        sx={{ width: 300, m: 3 }}

                        renderInput={(params) => <TextField {...params} label="Seleccione aspect radio del fondo" />}
                        onChange={(event, newValue) => {
                            //Cambiar datos dentro de un objeto que esta en un useState
                            let aspectRadio = {}
                            aspectRadio['bgRadio'] = newValue.id
                            setMemcardRequest({ ...memcardRequest, ...aspectRadio })
                        }}
                    />


                }

                {(inputSkin || isCustomBg) && !card &&
                    <Button variant="contained" sx={{ m: 3, width: 200 }} onClick={procesarMemcard}>
                        Generar vista previa
                    </Button>
                }



            </Box>

        </Grid>
    )
}
