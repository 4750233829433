import * as React from 'react';
import { Button,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,Slide} from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




export default function ConfirmCard({ open, handleClose, handleConfirm, request }) {

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                
                <DialogTitle>{"Confirmar vinculación"}</DialogTitle>
               
                <DialogContent dividers>
                
                <DialogContentText >
                       ¿Seguro que queres confirmar la vinculación de esta cuenta?       
                       Solo puedes vincular una cuenta por usuario, y no podrás cambiarla por unos dias.
                       Si no estas seguro, podes volver atrás y editar los datos.
                </DialogContentText>

                    <DialogContentText >
                       <h3>
                            Nombre de invocador: {request.summonerName}
                            <br />
                            Region: {request.region} 
                        </h3>
                       
                    </DialogContentText>
        
                </DialogContent>
                
                <DialogActions>
                    <Button color={'martha'} variant='outlined' onClick={handleClose}>Volver</Button>
                    <Button color={'martha'} variant='outlined' onClick={handleConfirm}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
