import { React, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {  Typography,  Paper, Grid, Button } from "@mui/material";
import LoadingCard from "./LoadingCard";
import ListChampsMastery from "./ListChampsMastery";

import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import { RiotProfileCard } from "./RiotProfileCard";
import { DiscordProfileCard } from "./DiscordProfileCard";
import { WallpaperProfile } from "./WallpaperProfile";
import {AddNewAccount} from './AddNewAccount'


const Profile = ({ bgData,setBgData }) => {
  const { user, isAuthenticated } = useAuth0();
  const { usr: userMetadata } = useContext(UserContext)
  
  const fechaConver = (fecha) => {
    const dateOfCreation = new Date(fecha).toLocaleDateString('es', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
    return dateOfCreation
  }

  const { riotData } = bgData;





  return (
    isAuthenticated && (<Grid container spacing={0} component={Paper} sx={{ height: '100vh', borderRadius: '0' }}>



      <Grid item xs={false} md={12} component={Paper} sx={{ alignContent: 'center' }}>


        {/* Wallpaper dinámico */
       }
       <WallpaperProfile bgData={bgData} imageProfile={user.picture}/>

        <Grid container spacing={0} component={Paper} elevation={5}>
          <Grid item xs={12} md={4}>
          
            <Typography variant={'h4'} sx={{ margin: 2,marginBottom:0 }} >
              {user.name}
            </Typography>
            <Typography variant={'subtitle2'} sx={{ margin: 2,marginTop:0 }} >
                Ultima sincronización: {fechaConver(user.updated_at)}
            </Typography>
          </Grid>
          <Grid item xs={0} md={6}></Grid>
          <Grid item xs={12} md={2}>

            {riotData ? <Button variant="outlined" sx={[{ border: '6px solid rgb(255 255 255 / 72%)', color: 'white', fontSize: '1.3em', background: "url(/img/9.jpg)", backgroundSize: '1500px', backgroundPosition: '70% 10%', padding: '2 rem', width: '100%', height: '5rem', fontFamily: 'KeepCalm', textTransform: 'none' }, { '&:hover': { border: '6px solid #f72121a1' } }]} color="primary" component={Link} to="/memcard">membercard</Button> : null}


          </Grid>
        </Grid>



        <Grid container spacing={0} component={Paper} elevation={5}>
          <Grid item md={12} xs={12} sx={{ m: 3 }}>
            <Typography align={'left'} variant={'h3'} >
              Mis cuentas
            </Typography>
          </Grid>
          {userMetadata ? (<>
          <DiscordProfileCard userMetadata={userMetadata} user={user} />
            {riotData ? <Link to="/profile/riot" style={{display:'contents'}}> <RiotProfileCard userMetadata={userMetadata}   bgData={bgData} />
              </Link> :  <AddNewAccount/>}

            </>) : <LoadingCard />

          }

          {/*riotData ? (<>
            <Grid item md={12} xs={12} sx={{ m: 3 }}>
              <Typography align={'left'} variant={'h3'} >
                Mi maestría
              </Typography>
            </Grid>
            {userMetadata ? <ListChampsMastery userMetadata={userMetadata} /> : <LoadingCard />
            }

          </>) : (<></>)*/}




        </Grid>
      </Grid>

    </Grid>


    )


  )
}

export default Profile;