import {Grid, Paper, Card, CardContent, Typography,Stepper,Step,StepLabel} from '@mui/material'

const Error404 = () => {

  const steps = ['Sitio Online', '404'];

  const isStepFailed = (step) => {
    return step === 1;
  };

  return(
    <Grid container component={Paper} sx={{ borderRadius: 0, overflow: 'hidden' }}>
      <Grid item xs={12} md={12}>
       
        <Card sx={{ background: `linear-gradient(to right, rgb(7 20 255 / 56%), rgb(3 2 10 / 84%)),url('/img/talonmuro.jpg')`, backgroundPosition: "50% 25%", backgroundSize: 'cover', minHeight: '54.3rem' }}>




        <CardContent> 

        <Stepper activeStep={1}>
        {steps.map((label, index) => {
          const labelProps = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
               Error
              </Typography>
            );

            labelProps.error = true;
          }

          return (
            <Step key={label}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
          </Stepper>
          
        

             <Typography align={'center'} variant={'subtitle1'} sx={{ fontFamily:'KeepCalm', margin: "11vw" }}>
            
            
             <img src="/img/yonesad.png" alt="Error"   />
             <Typography align={'center'} variant={'h3'} sx={{ fontFamily:'KeepCalm'}}>
              404
             ¿Te perdiste?   
              </Typography>     
          Solicitaste una URL inexistente, ¿Necesitas un mapa?
          </Typography>
        
         
          </CardContent>
        
        </Card>
       
       
       
        
        
        
   
      </Grid>
    
      <Typography align='right' variant='subtitle1' sx={{ fontStyle:'italic', position:'fixed', left:0,bottom:0,fontFamily:'Roboto-Thin'}}>
         Fanart @sonwooang , Yone @Curse_melted
      </Typography>
    </Grid>  )

}

export default Error404;