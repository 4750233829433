import { Typography, Avatar,  Grid,  Card, CardMedia, CardContent, Button} from "@mui/material";

export const DiscordProfileCard = ({ userMetadata }) => {

    const banner = userMetadata.discordData?.banner





    return (
        <Grid item xl={3} md={4} xs={11} sx={[{ marginLeft: 3, marginTop:3 ,textDecoration:'none',textTransform: 'none',transition:'.5s' },{'&:hover':{ transform: 'scale(1.05)'}}]}>
          <Button color='martha' sx={{p:0,m:0,display:'contents',textDecoration:'none', textTransform:'none'}}> 
           
            <Card >
                <CardContent sx={{ p: 0 }} >

                    {/*Banner, si es que hay */
                        banner ? (<CardMedia
                            component="img"
                            height="130"
                            image={banner}
                            alt="fondo"
                            sx={{ objectPosition: '45% 28%', objectFit: 'cover' }}
                        />) : (<Card sx={{ background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),rgb(87 100 241)`, minHeight:'130px' }}>  
                        </Card>)

                    }
                    <Avatar
                        alt="DiscordAvatar"
                        src={userMetadata.avatar}

                        sx={{ border: '8px solid #1F1B24', marginY: '-2.5em', marginX: '1.7em ', width: 90, height: 90, position: 'absolute', p: 0 }}
                    />
                    <Avatar
                        alt="Discord"
                        src="/img/discord.png"
                        sx={{ p: 0, position: 'absolute', border: '8px solid #1F1B24', marginY: '.8em', marginX: '5em', width: '25px', height: '25px' }}
                    />
                    <Typography align={'right'} variant={'h5'} sx={{ pt: 3.5, paddingRight: 3, pb: 0, fontStyle: 'bold' }}>
                        {userMetadata.discordData.username}#{userMetadata.discordData.discriminator}
                    </Typography>
                </CardContent>
            </Card>
            </Button>
        </Grid>
    ) 

    
}
