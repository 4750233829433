import { createTheme } from '@mui/material/styles';
import KeepCalm from './KeepCalm-Medium.ttf';
import RobotoThin from './Roboto-Thin.ttf';
import RobotoLight from './Roboto-Light.ttf';
import Zenzai from './Zenzai.ttf';

export const Theme = createTheme({
  typography: {
    fontFamily:
      'Roboto-Light,KeepCalm, Zenzai'
    
  },components: {
    MuiCssBaseline: {
      styleOverrides: `

        @font-face {
          font-family: 'KeepCalm';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('KeepCalm'), local('KeepCalm-Medium'), url(${KeepCalm}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Roboto-Thin';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Roboto-Thin'),local('Roboto-Thin') url(${RobotoThin}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Roboto-Light';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Roboto-Light'),local('Roboto-Light') url(${RobotoLight}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face{
          font-family:'Zenzai';
          src: local('Zenzai'),local('Zenzai') url(${Zenzai}) format('ttf');
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

        }
      `,
    },
  },

  palette: {
    mode: 'dark',
    primary: {
      main: '#4130db',
      darker:'#4130db'
    },
    secondary: {
      main: '#4e00f5',
    },
    martha:{
      main:'#ffffff'
    }
  },
});

