import { Card, Grid, Typography, CardContent } from '@mui/material'

const ErrorFetch = (props) => {

  return (
    <Grid item md={3} xs={11} sx={{ marginLeft: 3, marginBottom: 2 }}>
      <Card >
        <Card sx={{ background: `#d23f42` }}>
          <Typography align={'left'} variant={'h4'} sx={{ pt: 1.5, fontStyle: 'bold' }}>
          </Typography>
        </Card>
        <CardContent sx={{ p: 2 }} >
          <Typography align={'left'} variant={'h5'} sx={{ fontStyle: 'bold' }}>
            Error
          </Typography>
          <Typography align={'center'} variant={'p'} sx={{ fontStyle: 'bold' }}>
            {props.msj ? props.msj : "Ocurrió un error. Intentá mas tarde."}
          </Typography>

        </CardContent>
      </Card>

    </Grid>
  )
}

export default ErrorFetch;