import React from 'react';
import {Typography, Avatar, Grid,CardMedia,Button, Card, CardContent} from "@mui/material";

export const RiotProfileCard = ({userMetadata,bgData}) => {
 
  if (!bgData) return;
  const { listaSkinsId,elegirRandomSkin} = bgData;
  const bgPortada =  `https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/${listaSkinsId[elegirRandomSkin(listaSkinsId.length)].id}.jpg`
  const { riotData:{ profileIconId }, version } = userMetadata;
  const iconRiotURL = `https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${profileIconId}.png`
 
 

  return  (
    <>
    <Grid item xl={3} md={4} xs={11} sx={[{ marginLeft: 3, marginTop:3,textTransform: 'none',transition:'.5s' },{'&:hover':{ transform: 'scale(1.05)'}}]}>
           <Button color='martha' sx={{p:0,m:0,display:'contents',textTransform:'none'}}> <Card >

                <CardContent sx={{ p: 0 }} >

                    <CardMedia alt="fondo" component="div"
                        sx={{ background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),url(${bgPortada})`, backgroundPosition: "25% 25%", backgroundSize: '980px',minHeight:'130px',objectPosition: '25% 1%', objectFit: 'cover' }}>  
                    </CardMedia>

                    

                    <Avatar
                        alt="LoLAvatar"
                        src={iconRiotURL}
                        
                        sx={{ border: '8px solid #1F1B24', marginY: '-2.5em', marginX: '1.7em ', width: 90, height: 90, position: 'absolute', p: 0 }}
                    />

                    <Avatar
                        alt="RiotLogo"
                        src="/img/riot.png"
                        sx={{ p: 0,position:'absolute',border:'8px solid #1F1B24',marginY:'.8em',marginX: '5em', width: '25px', height: '25px' }}
                    />

                    <Typography align={'right'} variant={'h5'} sx={{ pt: 3.5, paddingRight: 3, pb: 0, fontStyle: 'bold' }}>
                    {`${userMetadata.riotData.name} - ${userMetadata.riotData.region}`}
                    </Typography>
                    

                </CardContent>

            </Card>
</Button>
        </Grid>


    
  </>)
  
}
