import {Card,Avatar} from '@mui/material'

export const WallpaperProfile = ({bgData, imageProfile}) => {
  const { listaSkinsId,elegirRandomSkin} = bgData;
  const bgPortada =  `https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/${listaSkinsId[elegirRandomSkin(listaSkinsId.length)].id}.jpg`

  return (
    <Card sx={{ background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),url(${bgPortada})`, backgroundPosition: "50% 25%", backgroundSize: 'cover', minHeight: '16rem' }}>
          <Avatar
            alt="ProfileImage"
            src={imageProfile}
            align='right'
            sx={{ border: '4px solid #fff', marginY: '2.5em', marginX: '1em ', width: 150, height: 150 }}
          />
        </Card>
  
  )
}
