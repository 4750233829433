import { Grid,Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { useForm } from "react-hook-form";
import ConfirmCard from './ConfirmCard';
import { useState } from 'react';


export const AddNewAccountForm = ({setIsFetching, setNewProfile}) => {

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState({})

  const onSubmit = async (data) => {
    setRequest(data)
    setOpen(true)
    //setIsFetching(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () =>{
    console.log("request",request)
    setOpen(false);
    setIsFetching(true)
    await setNewProfile(request)
    setIsFetching(false)
    

   
  }

  return (
    //formulario de vinculacion
    open ? <ConfirmCard open={open} handleClose={handleClose} handleConfirm={handleConfirm} request={request} /> :
    <form onSubmit={handleSubmit(onSubmit)}>
       <Grid container sx={{display:'flex',flexDirection:'row',gap:2,alignContent:'center'}}>
      

         
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant={'h6'} sx={{ fontFamily: 'Roboto-Thin' }}>
          Summoner Name
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
        <TextField
          error={errors.summonerName}
          id="outlined-basic"
          label="Nombre de invocador" 
          variant="outlined"
          helperText={errors.summonerName && "Este campo es requerido"}
          {...register("summonerName", { required: true })}
          sx={{ width: '50%' }}
        />
        
      </Grid>
      
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant={'h6'} sx={{ fontFamily: 'Roboto-Thin' }}>
          Region
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FormControl variant="outlined" sx={{ width: '50%' }}>
          <InputLabel id="region_select">Seleccione Region...</InputLabel>
          <Select
            labelId="region_select"
            id="region_select"
            label="Region"
            error={errors.region}
            {...register("region", { required: true })}
          >
            <MenuItem value={'BR1'}>BR</MenuItem>
            <MenuItem value={'EUN1'}>EUNE</MenuItem>
            <MenuItem value={'EUW1'}>EUW</MenuItem>
            <MenuItem value={'JP1'}>JP</MenuItem>
            <MenuItem value={'KR'}>KR</MenuItem>
            <MenuItem value={'LA1'}>LAN</MenuItem>
            <MenuItem value={'LA2'}>LAS</MenuItem>
            <MenuItem value={'NA1'}>NA</MenuItem>
            <MenuItem value={'OC1'}>OC</MenuItem>
            <MenuItem value={'TR1'}>TR</MenuItem>
            <MenuItem value={'RU'}>RU</MenuItem>
          </Select>
         
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="contained" type={'submit'} sx={{ width: '50%' }}>
          Vincular
        </Button>
      </Grid>
     

      
    </Grid>
    </form>
   



  )
}
