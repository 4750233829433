import { Grid, Typography, Button } from '@mui/material'
import { useApiProfile } from '../../hooks/useApiProfile'

export const ValidateImage = ({ setIsFetching, img_verification, setError, error }) => {

    const img_url = `https://ddragon.leagueoflegends.com/cdn/11.18.1/img/profileicon/${img_verification}.png`

    const { validateAccount } = useApiProfile()

    const handleValidateAccount = async () => {
        setIsFetching(true)
        const response = await validateAccount()
        if (response.result === "error") {
            setError({ error: true, message: response.message })
        }
        setIsFetching(false)
        if(response.result === "success"){
            window.location.reload()
        }
    }

    return (
        <Grid container sx={{ display: 'flex', flexDirection: 'grid', gap: 2, alignContent: 'center', justifyContent: 'center' }}>
            <Grid item xs={12} md={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6' align={'center'}>Ve a League of Legends, cambia tu imagen de perfil a la siguiente y luego presiona verificar.</Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={img_url} alt="img_verification" />
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="contained" onClick={() => handleValidateAccount()}>Verificar</Button>
                
            </Grid>
            <Typography variant="body1" align={'center'} color="error">
                    {error.message}
                </Typography>
        </Grid>

    )
}
