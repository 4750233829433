import { Typography, Paper, Grid, CardMedia } from "@mui/material";

export const PortadaMiniSite = () => {
    return (<>
        <Typography align={'center'} variant={'h3'} sx={{ fontFamily: 'KeepCalm', margin: "1.5em", position: 'absolute' }}>
            membercard 
        </Typography>

<img src={"/img/4.jpg"} height="200px" width={"100%"} alt="portada" style={{objectPosition:'45% 10%', objectFit:'cover'}}/>

      
    </>
    )
}
