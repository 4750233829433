import { React, useEffect, useState, useMemo} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack'
import { Typography, LinearProgress, Grid } from '@mui/material';
import { useSpring, animated } from 'react-spring'
import { MemberCardSuccess } from './MemberCard/MemberCardSuccess'



const LoadingMemcard = (props) => {
    const [mensajeRancio, setMensajeRancio] = useState('Cargando...')
   
    const [isReady, setIsReady] = useState(false)
    const [bgUrl, setbgURL] = useState('/img/7.jpg')
    const card = localStorage.getItem('memcardPreview') ? localStorage.getItem('memcardPreview') : null

    const [flipped, setFlipped] = useState(false)
    const { transform, opacity } = useSpring({
        transform: `perspective(800px) rotateY(${flipped ? 15 : 0}deg)`,
        config: { mass: 25, tension: 25, friction: 20 },
    })



    useEffect(() => {

        const img_url = `https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/${props.bgimg}.jpg`
       // console.log(img_url)
        if (props.wallpaperURL) {
            setbgURL(props.wallpaperURL)

        } else {

            setbgURL(img_url)



        }


        setTimeout(() => {
            setMensajeRancio(props.message)
        }, 1000)
        setTimeout(() => {
            setMensajeRancio('Cargando... No cierres la ventana')
        }, 5000)
        setTimeout(() => {
            setIsReady(true)
        }, 8000)





    }, [])

    useEffect(() => {
        setTimeout(() => {
            setFlipped(!flipped)
        }, 1800)

    }, [flipped])


    return (
        isReady ? (<MemberCardSuccess bg={bgUrl} memcardRequest={props.memcardRequest} />) : (

            <Grid item md>
                <Backdrop

                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, background: `linear-gradient(to right, rgb(59 59 183 / 0%), rgb(74 90 163)),url(${bgUrl}),black`, backgroundSize: 'cover' }}
                    open={true}
                >


                    <Stack spacing={1} sx={{ alignItems: 'center' }}>

                        <img src="/img/tarmydotcomlogowhite.png" alt="talonarmylogo" style={{ zIndex: 2 }} />


                        {
                            card ? <>
                                <Grid item xs></Grid>
                                <Grid item md={8} xs={5}>
                                    <animated.div style={{ opacity, transform }}>
                                        <img src={card} alt="Cargando" width={'100%'} height={'100%'} />
                                        <LinearProgress color='inherit' sx={{ marginRight: '2em', marginLeft: '2em' }} />
                                    </animated.div>
                                </Grid>
                                <Grid item xs></Grid>

                            </>


                                : <CircularProgress color="inherit" />
                        }


                        <br />
                        <Typography >{mensajeRancio}

                        </Typography>


                    </Stack>




                </Backdrop>
            </Grid>
        ))

}

export default LoadingMemcard;