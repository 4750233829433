import { useState, useContext } from "react"
import UserContext from "../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";


export const useMemcardGen = () => {

    const { user } = useAuth0();
    const { usr: userMetadata } = useContext(UserContext)

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

    const [memcardRequest, setMemcardRequest] = useState({
        lolName: userMetadata.riotData.name,
        masteryPoints: `${numberWithCommas(userMetadata.masteryData[0].championPoints)} - ${userMetadata.masteryData[0].name}`,
        discordTag: `${userMetadata.username}#${userMetadata.discriminator}`,
        cardNumber: "000000000000",
        serverLoL: userMetadata.riotData.region,
        skinId: "Talon_0",
        preId: "b",
        avatarURL: user.picture.replace('.webp', '.png'),
        bgRadio: 3
    });

    
    return [memcardRequest, setMemcardRequest]

}
