import { React, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Grid, Paper, CardMedia, LinearProgress, Typography } from '@mui/material';





const LoginInterface = () => {
    const [isLoading, setLoading] = useState(false)
    const [bgImage, setBgImage] = useState('7')
   const bg = ['wallp1', '1', '3', '2', 'wallp2', '6', '4', '7', '9']

    useEffect(() => {
        const random = Math.floor(Math.random() * bg.length)

        setBgImage(bg[random])

    }, [])
    const { loginWithRedirect } = useAuth0()
    return (

        <Grid container component="main" sx={{
            backgroundImage: `url(/img/${bgImage}.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            
            minHeight: '100vh'
        }}>

            <Grid item xs={false} md={12} >
                {isLoading &&
                    <LinearProgress variant="indeterminate" color="error" />
                }
            </Grid>

            <CssBaseline />


{bgImage=='9' || bgImage=='2' ? <Grid item md={1}>

            </Grid>: <Grid item md={8}>

</Grid>
}
            

            <Grid item xs md={3} sx={{ background: '#000000b0',border:'.5em solid #660aff52', display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '4em' }}> 


                <Typography variant="h2" sx={{ marginBottom: '1em', marginTop: '1em' }}>
                    <span style={{ fontFamily: 'Zenzai' }}>TalonArmy</span>
                    <span style={{ fontFamily: 'KeepCalm', fontSize: '.4em' }}>.com </span>
                
                </Typography>


                <Typography variant="h5" align={'right'} >
                   
                    Inicia sesión para continuar.
                    
                </Typography>
<LinearProgress variant="indeterminate" color="error" />
                <Button variant="outlined" color='inherit' sx={{ width: '100%', marginTop: '2em' }} onClick={() => {
                    setLoading(true)
                    setTimeout(() => {
                        loginWithRedirect()
                    }, 1200)

                }}>Iniciar Sesión   <img src="/img/tpasslogowhite.png" alt="logotpass" width="80px" height="40px" /></Button>








        
            </Grid>
            <Grid item md={12}>
                
                <Typography variant='subtitle2' align='right'>
                  
                </Typography>

            </Grid>


        </Grid>









    )
}

export default LoginInterface;