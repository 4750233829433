import { Card,Grid,Avatar, Typography, Skeleton, CardContent, CardMedia } from '@mui/material'

const LoadingCard = ({message}) => {

 

  return (
    <Grid item md={3} xs={11} sx={{ marginLeft: 3, marginBottom: 2 }}>
            <Card >

              <CardContent sx={{ p: 0 }} >
                <CardMedia
                  height="100"
                  alt="Cargando"
                ><Skeleton sx={{ height: 90 }} animation="wave" variant="rectangular" /></CardMedia>

                <Avatar
                  alt={'Cargando'}
                  align='center'
                  sx={{ margin: '0 auto', width: 80, height: 80 }}
                >
                  <Skeleton animation="wave" variant="circular" width={80} height={80} />
                </Avatar>



                <Typography align={'center'} variant={'h4'} sx={{ fontStyle: 'bold' }}>
                  {message}
                </Typography>

              </CardContent>
            </Card>

          </Grid>
  )


}

export default LoadingCard;