import {LinearProgress} from '@mui/material'
import { useEffect, useState} from 'react';

const LoadingBar = ({loadBarValue}) =>{

  const [value,setValue] = useState(0);

  useEffect(()=>{
  
  const myInterval = setInterval(()=>{
      if(value<100 || loadBarValue<100){
        setValue(previousValue=>       
          loadBarValue || previousValue+15)     
      } 
    },200)

    return ()=>{
      clearInterval(myInterval)
    }
  },[loadBarValue,value])

  return( value<100 ? <LinearProgress variant="determinate" value={value} color="error" />:<></>)
}

export default LoadingBar;