import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getDDragonVersion } from '../services/versionService'

export const useApiProfile = () => {

  const { getAccessTokenSilently } = useAuth0();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [userMetadata, setUserMetadata] = useState(null);

  const getUserMetadata = async () => {

    const domain = "api.talonarmy.com";

    try {

      const token = sessionStorage.getItem("token_tarmy")

      let accessToken;

      if(token){
        accessToken = token
      }else{
       const tokenauth0 = await getAccessTokenSilently({
          audience: `https://${domain}`,
          scope: "read:profile",
        })
        accessToken = tokenauth0
        sessionStorage.setItem("token_tarmy", tokenauth0);
      }
     



      const userDetailsByIdUrl = `https://${process.env.REACT_APP_TARMY_API_URL}/s/profile`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const response = await metadataResponse.json();

      const version = await getDDragonVersion()
      setIsLoadingProfile(false)

      response.version = version;

      return response
    } catch (e) {
      console.log(e);
      return e

    }
  };

  const getValidationState = async () => {
    try {
      const url = `https://${process.env.REACT_APP_TARMY_API_URL}/s/riot/validation`;
      const token = sessionStorage.getItem("token_tarmy")
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err)
    }

  }

  const setNewProfile = async (newProfile) => {

    try {
      const url = `https://${process.env.REACT_APP_TARMY_API_URL}/s/profile`;
      const token = sessionStorage.getItem("token_tarmy")
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newProfile)
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err)
    }

  }

  const validateAccount = async () => {
    try {
      const url = `https://${process.env.REACT_APP_TARMY_API_URL}/s/riot/validation`;
      const token = sessionStorage.getItem("token_tarmy")
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },       
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err)
    }
  }





  useEffect(() => {
    getUserMetadata().then((response) => {

      setUserMetadata(response);
      setIsLoadingProfile(false)
    });
  }, [setUserMetadata]);

  return { userMetadata,isLoadingProfile, setUserMetadata, getValidationState, setNewProfile, validateAccount};
}


