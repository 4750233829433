import { Grid, Step, Stepper, Typography, StepLabel, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { AddNewAccountForm } from './AddNewAccountForm'
import { useEffect, useState } from 'react'
import { useApiProfile } from "../../hooks/useApiProfile";
import { ValidateImage } from './ValidateImage';


export const ValidationStatus = ({ setIsFetching }) => {

    //console.log(validationState)

    const { getValidationState, setNewProfile } = useApiProfile()
    const [validationState, setValidationState] = useState({})
    const [error, setError] = useState(false)

    useEffect(() => {
        const validationState = async () => {
            const state = await getValidationState()
            setValidationState(state)
            //setIsFetching(false)
        }
        validationState()

    }, [setValidationState, setIsFetching])


    const isStepFailed = (step) => {
        return step === 1 && error;
    };


    const steps = ['Ingresar datos', 'Check cuenta', 'Cuenta validada'];

    const actualStep = ({ status }) => {
        switch (status) {
            case "not_vinculated": return 0;
            case "pending_validation": return 1;
            case "vinculated": return 2;
        }
    };

    const actualLabel = ({ status, img_verification }) => {
        switch (status) {
            case "not_vinculated": return <AddNewAccountForm setIsFetching={setIsFetching} setNewProfile={setNewProfile} />
            case "pending_validation": return <ValidateImage setIsFetching={setIsFetching} error={error} setError={setError} img_verification={img_verification} />
            case "vinculated": return <Button variant="contained" component={Link} to={'/profile/riot'}>Ir a mi perfil</Button>;
        }
    }
    return (
        <Grid container sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stepper activeStep={actualStep(validationState)}>
                    {steps.map((label, index) => {
                        const labelProps = {};
                        if (isStepFailed(index)) {
                            labelProps.optional = (
                                <Typography variant="caption" color="error">
                                    Error
                                </Typography>
                            );

                            labelProps.error = true;
                        }

                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>

            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1em' }}>
                {actualLabel(validationState)}
            </Grid>



        </Grid>

    )
}
