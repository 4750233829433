import { getDDragonVersion } from './versionService'

  const getChampionList = async () => {
    try {
      const version = await getDDragonVersion();
      const champData = await fetch(`https://${process.env.REACT_APP_RIOT_CDN_URL}/${version}/data/en_US/championFull.json`)
      const championData = await champData.json();
      const champs = Object.entries(championData.keys)
      return champs.map(champ => {
        if (champ[1] === 'MonkeyKing') return { label: 'Wukong', id: champ[0] }  
        return { label: champ[1], id: champ[0], }
      })
     
    } catch (err) {
      console.log(err)
      return [{ label: 'Error al obtener la lista de campeones. Intentá mas tarde.', id: 'error' }]
    }
  }



  const getSkinsList = async (champId) => {
    try {
      const version = await getDDragonVersion();
      
        const champData = await fetch(`https://${process.env.REACT_APP_RIOT_CDN_URL}/${version}/data/en_US/championFull.json`)
        const championData = await champData.json();
        champId.toLowerCase()
        const champs = Object.entries(championData.data)
        const champsSkins = champs.map((champion) => {
          const skins = champion[1].skins.map(skin => { 
            if(champion[0]==='Fiddlesticks') return { name: skin.name, id: `FiddleSticks_${skin.num}` } 
            return { name: skin.name, id: `${champion[0]}_${skin.num}` } })
          return { name: champion[0], skins }
        })
          const champList = Object.entries(championData.keys)
          const champBuscado = champList.find((champion) => {
            if (champion[0] === champId) return champion
            else if (champion[1].toLowerCase() === champId) return champion
          })
    
          return champsSkins.find((champion) => champion.name === champBuscado[1])
        
    
    } catch (err) {
      console.log(err)
      return [{ label: 'Error al obtener la lista de skins. Intentá mas tarde.', id: 'error' }]
    }

  }

  export { getChampionList, getSkinsList}


