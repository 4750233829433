import { Typography, Avatar, Grid, Card, CardMedia, CardContent, Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
export const AddNewAccount = () => {

    const bgPortada = `https://ddragon.leagueoflegends.com/cdn/img/champion/centered/Yasuo_54.jpg`

    return (
        <Grid item xl={3} md={4} xs={11} sx={[{ marginLeft: 3, marginTop:3,textTransform: 'none',textDecoration:'none',transition:'.5s' },{'&:hover':{ transform: 'scale(1.05)'}}]} component={Link} to="/riot/vinculate">
            <Card >

                

                <CardContent sx={{ p: 0 }} >

   

                    <CardMedia alt="fondo" component="div"
                        sx={[
                            { background: `url(${bgPortada})`, 
                        backgroundPosition: "25% 20%", 
                        backgroundSize: '850px',
                        minHeight:'130px',
                        objectPosition: '25% 1%', 
                        objectFit: 'cover',transition:'background .5s ease-in-out'},
                        
                        {'&:hover':{background:'url(https://ddragon.leagueoflegends.com/cdn/img/champion/centered/Yasuo_55.jpg)',
                        backgroundPosition: "25% 20%", 
                        backgroundSize: '850px',
                        minHeight:'130px',
                        objectPosition: '25% 1%',
                        
                        objectFit: 'cover'}} ]}>  
                    </CardMedia>

                    

                    <Avatar
                        alt="avatar"
                        src={"/img/riot.png"}
                        
                        sx={{ border: '8px solid #1F1B24', marginY: '-2.5em', marginX: '1.7em ', width: 90, height: 90, position: 'absolute', p: 0 }}
                    />

                    <Avatar
                        alt="+"
                        src=""
                        sx={{ p: 0,position:'absolute',border:'8px solid #1F1B24',marginY:'.8em',marginX: '5em', width: '25px', height: '25px' }}
                    />

                    <Typography align={'right'} variant={'h5'} sx={{ pt: 3.5, paddingRight: 3, pb: 0, fontStyle: 'bold' }}>
                    Vincular cuenta Riot
                    </Typography>
                    

                </CardContent>

            </Card>

        </Grid>

    )
}
