import LoginInterface from './components/LoginInterface';
//import LogoutBotton from './components/LogoutButton';
import NavBar from './components/NavBar'
import Profile from './components/profile/Profile'
import Loading from './components/Loading'
import Main from './components/Main';
import MemberCard from './components/MemberCard/MemberCard'
import IdxYasBot from './components/YasuoBot/IdxYasBot';
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { Theme } from './theme/Theme'
import LoadingBar from './components/loadingBar/loadingBar';
import { UserProvider } from './context/UserContext';

import { useState, useEffect } from 'react'
import Error404 from './components/errorPages/Error404';
import { useRiotWallpaper } from './hooks/useRiotWallpaper';
import { RiotProfile } from './components/profile/riotProfile/RiotProfile';
import { RiotVinculate } from './components/RiotVinculate';
import { useApiProfile } from './hooks/useApiProfile';

function App() {


  const {userMetadata, setUserMetadata, isLoadingProfile} = useApiProfile();

  const [loadBarValue, setLoadBarValue] = useState();

  const { isAuthenticated, isLoading, user  } = useAuth0();

  const [bgData, setBgData] = useRiotWallpaper({ userMetadata });

  if (isLoading || isLoadingProfile) return (<Loading />)



  //Defino el usermetadata para el context provider
  return (
    <UserProvider value={{ usr: userMetadata }}>
      <ThemeProvider theme={Theme}>
        <div className="App">
          {
            isAuthenticated ? <> <LoadingBar loadBarValue={loadBarValue} /> <NavBar /> </> : <LoginInterface />
          }
          <Routes>
            <Route path="/" element={user ? <Main usr={user} bgData={bgData} setBgData={setBgData} /> : null} />

            <Route path="/memcard" element={userMetadata && userMetadata.riotData ? <MemberCard loadBarValue={loadBarValue} setLoadBarValue={setLoadBarValue} /> : <Profile setLoadBarValue={setLoadBarValue} setUserMetadata={setUserMetadata} bgData={bgData} setBgData={setBgData} />} />
            <Route path="/profile" element={<Profile setLoadBarValue={setLoadBarValue} setUserMetadata={setUserMetadata} bgData={bgData} setBgData={setBgData} />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/yasuobot" element={<IdxYasBot />} />
            <Route path="/profile/riot" element={userMetadata && userMetadata.riotData ? <RiotProfile bgData={bgData} /> : <Profile setLoadBarValue={setLoadBarValue} setUserMetadata={setUserMetadata} bgData={bgData} setBgData={setBgData} />} />
            <Route path="/riot/vinculate" element={<RiotVinculate />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </ThemeProvider>
    </UserProvider>

  );



}

export default App;
