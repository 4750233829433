import { Card, Grid, Avatar, Typography, CardContent, CardMedia } from '@mui/material'
import ErrorFetch from './ErrorFetch';
import { Tilt } from '../Tilt';


const ListChampsMastery = (props) => {

  const masteryData = props.userMetadata ? props.userMetadata.masteryData : false

  const version = props.userMetadata.version

  const url_icon = `https://${process.env.REACT_APP_RIOT_CDN_URL}/${version}/img/champion`


  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const fechaUltimoJuego = (fecha) => {
    const dateOfCreation = new Date(fecha).toLocaleDateString('es', { year: 'numeric', month: 'numeric', day: 'numeric' })
    return dateOfCreation
  }

  return (<>
    {
      masteryData ?
        <>
       
          {masteryData.map((champ, index) => {

            const img = `${url_icon}/${masteryData[index].name}.png`
            
            return (
              
                <Grid key={index} item md={3} lg={3.5} xs={11} sx={[{  textDecoration:'none',textTransform: 'none',transition:'.5s' },{'&:hover':{ transform: 'scale(1.05)'}}]}>
               <Tilt>
                  <Card sx={{}}>
                  <CardMedia alt="fondo" component="div"
                    sx={{ background: `linear-gradient(to right, rgb(20 3 161 / 23%), rgb(13 31 114 / 6%)),url(https://${process.env.REACT_APP_RIOT_CDN_CENTERED_IMAGES_URL}/${champ.name}_0.jpg)`, backgroundPosition: "25% 25%", backgroundSize: '880px', minHeight: '250px', objectPosition: '28% 6%', objectFit: 'cover' }}>
                  </CardMedia>

                  <CardContent sx={{ position:'absolute', marginTop:'-12em', background:'#12121285', borderRadius:'0% 10% 10% 0%'}} >





                    <Avatar
                      alt={`${champ.name}_icon`}
                      src={img}
                      align='center'
                      sx={{ border: '6px solid #1F1B24', marginY: '-2.5em', marginX: '1.7em ', width: 90, height: 90, position: 'absolute' }}
                    />
                    <Avatar
                      alt={`${champ.championLevel}`}
                      src={`/img/mastery/mastery-${champ.championLevel}.png`}
                      align='center'
                      sx={{ marginX: '4rem', position: 'absolute', marginY: '1.5rem', width: 40, height: 40 }}
                    />

                    <Typography align={'right'} variant={'body1'} sx={{ fontSize: '2em', fontStyle: 'bold', marginX: '.5em' }}>
                      {champ.name}
                    </Typography>



                    <Typography align={'right'} variant={'h6'} sx={{ fontStyle: 'bold', marginX: '1em' }}>

                      {numberWithCommas(champ.championPoints)}
                      <br />   Ultimo juego: {fechaUltimoJuego(champ.lastPlayTime)}

                    </Typography>



                  </CardContent>
                </Card>
              
                 </Tilt>

              </Grid>
             
              
            )

            
          })}
          
          </> : <ErrorFetch msj={"Hubo un inconveniente al procesar información sobre tus campeones. Intentá mas tarde."} />


    }

  </>
  )

}

export default ListChampsMastery;