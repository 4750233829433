import { useState, useRef, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Paper, Grid, Button } from "@mui/material";

//import { generateMemcard } from '../services/memcardService'
import { useMemcardGen } from '../../hooks/useMemcardGen'
import LoadingMemcard from '../LoadingMemcard'
import { MemberCardForm } from './MemberCardForm'
import MemcardPreview from "./MemcardPreview"
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { PortadaMiniSite } from "./PortadaMiniSite";


import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import ConfirmacionCard from './ConfirmacionCard'

const MemberCard = ({ setLoadBarValue }) => {

  const theme = useTheme();

  const { isAuthenticated } = useAuth0();
  const [errorCard, setErrorCard] = useState({})
  //console.log('datosuser',userMetadata)
  const [isLoadingMem, setLoadingMem] = useState(false)
  const [card, setCard] = useState()
  const [memcardRequest, setMemcardRequest] = useMemcardGen()
  const canvasRef = useRef(null)
  const procesarMemcard = async () => {
    setCard(false)
    setLoadingMem(true)

    setTimeout(() => {
      setCard(true)
      setLoadingMem(false)
    }, 1500)


    /* await generateMemcard(memcardRequest).then(datos => {
       if (datos.error) {
         setErrorCard({ flag: true, msj: datos.error })
         setLoadingMem(false)
       }
       else {
         setCard(datos)
         setTimeout(() => {
           setLoadingMem(false)
         }, 1500)
 
       }
     }) */
  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Cuando cargo por primera vez
  useEffect(() => {
    localStorage.removeItem('memcardPreview')
  }, [])

  const handleConfirm = () => {
    setOpen(false)
    const memcardPreview = canvasRef.current.toDataURL("image/png")
    localStorage.removeItem('memcardPreview')
    localStorage.setItem('memcardPreview', memcardPreview)
    setLoadingMem(true)
  }




  return (
    isLoadingMem ? (<LoadingMemcard bgimg={memcardRequest.skinId} memcardRequest={memcardRequest} wallpaperURL={memcardRequest.wallpaperURL} message={'Estamos generando tu membercard, con el fondo especificado...'} />) :
      isAuthenticated && (

        <Grid container component={Paper} spacing={0} sx={{ minHeight: '90vh', overflow: 'hidden', borderRadius: '0' }} >

          {/*  Portada */}
          <PortadaMiniSite />
          {/* FORMULARIO */}
          <MemberCardForm setLoadBarValue={setLoadBarValue} procesarMemcard={procesarMemcard} memcardRequest={memcardRequest} setMemcardRequest={setMemcardRequest} card={card} />

          {/*card && <Grid item xs={12} md={5} sx={{ m: 2, alignContent: 'left' }} >
            <CardMedia component="img"
              image={`data:image/png;base64,${card}`}
              alt="Membercard" />
            </Grid>*/}

          {card && <Grid item md sx={{ m: 3, alignContent: 'left', position: 'relative' }} >

            <Typography align={'left'} variant={'h6'} sx={{ m: 2 }}> <AutoGraphIcon /> <br />Vista previa</Typography>
            <Typography align={'left'} variant={'body2'} sx={{ m: 2 }}>Asi se ve tu memcard, podes probar otros fondos cambiando en el menu a la izquierda.</Typography>
          </Grid>
          }


          {card && <Grid item sx={[{ marginTop: 3, width: '808', height: '500', alignContent: 'left', transition: '.5s' }, { '&:hover': { transform: 'scale(1.05)' } }, { '@media (max-width: 1200px)': { marginLeft: '.5em' } }]} >
          
            <Button onClick={handleClickOpen} color='martha' sx={{ p: 0, m: 0, display: 'contents', textTransform: 'none' }}>
            
              <MemcardPreview body={memcardRequest} canvasRef={canvasRef} />
             
            </Button>

          </Grid>
          }

          {open && <ConfirmacionCard open={open} canvasRef={canvasRef} handleClose={handleClose} handleConfirm={handleConfirm} />}





          <Grid item xs>

          </Grid>






          {errorCard.flag && <Typography align={'left'} variant={'h5'} sx={{ m: 2 }}>
            {errorCard.msj}
          </Typography>}



          <Grid item xs={12} md={12} sx={{ height: "40vh" }}></Grid>
        </Grid>

      )


  )
}

export default MemberCard;